<template>
  <div>
    <div class="row">
      <div class="col-sm-12">
        <div class="card">
          <div class="card-header">
            <div class="row">
              <div class="col-md-4 d-flex justify-content-between align-items-center">
                <div class="header-title me-5">
                  <h4 class="card-title">Категории</h4>
                </div>
                <button
                  type="button"
                  class="btn btn-primary"
                  data-bs-toggle="modal"
                  data-bs-target="#new-flights"
                >
                  Добавить
                </button>
              </div>
            </div>
          </div>

          <div class="card-body px-0">
            <div class="table-responsive" style="min-height: 250px;">
              <table id="user-list-table" class="table overflow-auto" role="grid" data-toggle="data-table">
                <thead>
                <tr class="ligth">
                  <th v-for="(header, index) in tableHeaders" :key="index">{{ header }}</th>
                </tr>
                </thead>
                <tbody v-if="categories?.length">
                <tr v-for="(category, index) in categories" :key="index">
                  <td>{{ category.name.uz }}</td>
                  <td>{{ category.name.ru }}</td>
                  <td>{{ category.name.eng }}</td>
                  <td>
                    <span v-if="category.active === 1" class="badge bg-success">Активный</span>
                    <span v-else class="badge bg-danger">Не Активный</span>
                  </td>
                  <td>
                    <div class="dropdown">
                      <a class="btn btn-primary dropdown-toggle" href="#" role="button" id="dropdownMenuLink"
                         data-bs-toggle="dropdown" aria-expanded="false">
                        Опции
                      </a>
                      <ul class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                        <li>
                          <a class="dropdown-item" href="#" data-bs-toggle="modal"
                             data-bs-target="#edit-category" @click.prevent="selectCategory(category.id)">Редактировать</a>
                        </li>
                        <li>
                          <button class="dropdown-item btn-link text-danger" @click="deleteCategory(category.id)">
                            Удалить
                          </button>
                        </li>
                      </ul>
                    </div>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div v-if="categories?.pagination?.total > 10" class="card-footer d-flex justify-content-center">
            <div class="bd-content">
              <CustomPagination
                :total-pages="categories?.pagination?.total_pages || 1"
                :total="categories?.pagination?.total || 1"
                :per-page="10"
                :current-page="currentPage"
                @pagechanged="onPageChange"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- create Modal -->
    <modal
      ref="ordersModal"
      mainClass="fade"
      :tabindex="-1"
      id="new-flights"
      ariaLabelled="addModalLabel"
      :ariaHidden="true"
      dialog-class="modal-lg modal-dialog-scrollable"
    >
      <model-header :dismissable="true"></model-header>

      <model-body class="px-5">
        <h1 class="text-center">Новая категория</h1>

        <Form v-slot="{ meta }" class="row mt-4" @submit="addCategory">
          <div class="col-md-4">
            <label for="names[uz]" class="form-label">Наеминования (uz)</label>
            <Field
              id="names[uz]"
              type="text"
              class="form-control"
              rules="required"
              name="names[uz]"
            />
            <ErrorMessage name="user_id" class="text-danger" />
          </div>

          <div class="col-md-4">
            <label for="names[ru]" class="form-label">Наеминования (ru)</label>
            <Field
              id="names[ru]"
              type="text"
              class="form-control"
              rules="required"
              name="names[ru]"
            />
            <ErrorMessage name="user_id" class="text-danger" />
          </div>

          <div class="col-md-4">
            <label for="names[eng]" class="form-label">Наеминования (eng)</label>
            <Field
              id="names[eng]"
              type="text"
              class="form-control"
              rules="required"
              name="names[eng]"
            />
            <ErrorMessage name="user_id" class="text-danger" />
          </div>

          <div class="col-md-4 d-flex align-items-center">
            <div class="form-check form-switch mb-3">
              <input v-model="isActive" class="form-check-input" type="checkbox" name="status"
                     id="flexSwitchCheckDefault">
              <label class="form-check-label" for="flexSwitchCheckDefault">Активный</label>
            </div>
          </div>

          <div class="col-md-4 offset-4 d-flex align-items-end justify-content-end mt-4">
            <button type="submit" class="btn btn-primary w-100" :disabled="!(meta.valid && meta.dirty)">Сохранить</button>
          </div>
        </Form>

        <br><br>
      </model-body>
    </modal>

    <!-- edit Modal -->
    <modal
      ref="ordersEditModal"
      mainClass="fade"
      :tabindex="-1"
      id="edit-category"
      ariaLabelled="addModalLabel"
      :ariaHidden="true"
      dialog-class="modal-xl modal-dialog-scrollable text-black"
    >
      <model-header :dismissable="true"></model-header>

      <model-body class="px-5">

        <div v-if="!categoryData">Загрузка....</div>

        <div v-else>
          <h1 class="text-center">Рейс #{{ categoryData.id }}</h1>

          <Form v-slot="{ meta }" class="row mt-4" @submit="editCategory">
            <div class="col-md-4">
              <label for="names[uz]" class="form-label">Наеминования (uz)</label>
              <Field
                v-model="categoryData.name.uz"
                id="names[uz]"
                type="text"
                class="form-control"
                rules="required"
                name="names[uz]"
              />
              <ErrorMessage name="user_id" class="text-danger" />
            </div>

            <div class="col-md-4">
              <label for="names[ru]" class="form-label">Наеминования (ru)</label>
              <Field
                v-model="categoryData.name.ru"
                id="names[ru]"
                type="text"
                class="form-control"
                rules="required"
                name="names[ru]"
              />
              <ErrorMessage name="user_id" class="text-danger" />
            </div>

            <div class="col-md-4">
              <label for="names[eng]" class="form-label">Наеминования (eng)</label>
              <Field
                v-model="categoryData.name.eng"
                id="names[eng]"
                type="text"
                class="form-control"
                rules="required"
                name="names[eng]"
              />
              <ErrorMessage name="user_id" class="text-danger" />
            </div>

            <div class="col-md-4 d-flex align-items-center">
              <div class="form-check form-switch mb-3">
                <input
                  :checked="categoryData.active === 1"
                  class="form-check-input"
                  type="checkbox"
                  name="active"
                  id="flexSwitchCheckDefault"
                  @change="onChangeStatus"
                />
                <label class="form-check-label" for="flexSwitchCheckDefault">Активный</label>
              </div>
            </div>

            <div class="col-md-4 offset-4 d-flex align-items-end justify-content-end mt-4">
              <button type="submit" class="btn btn-primary w-100" :disabled="!(meta.valid && meta.dirty)">Сохранить</button>
            </div>
          </Form>

        </div>
      </model-body>
    </modal>
  </div>
</template>

<script>
import { ErrorMessage, Field, Form } from 'vee-validate'
import modal from '@/components/bootstrap/modal/modal'
import modelHeader from '@/components/bootstrap/modal/model-header'
import modelBody from '@/components/bootstrap/modal/model-body'
import CustomPagination from '@/components/custom/pagination/CustomPagination'

import { createCategory, getCategories, updateCategory, removeCategory } from '@/services/categories.service'

export default {
  name: 'FligthsIndex',
  data: () => ({
    currentPage: 1,
    categoryData: null,
    categories: null,
    isActive: false,
    isLoading: {
      order: false
    },
    tableHeaders: [
      'Наеминования (uz)',
      'Наеминования (ru)',
      'Наеминования (eng)',
      'Активный',
      'Действия'
    ]
  }),
  components: {
    CustomPagination,
    modal,
    modelBody,
    modelHeader,
    Form,
    Field,
    ErrorMessage
  },
  methods: {
    onPageChange (page) {
      this.currentPage = page
    },
    selectCategory (id) {
      this.categoryData = this.categories.find((flight) => flight.id === id)
    },
    onChangeStatus (e) {
      this.isActive = e.target.checked
    },
    async addCategory (values) {
      const formData = new FormData()

      formData.append('active', this.isActive ? 1 : 0)
      Object.entries(values).forEach(([name, value]) => {
        formData.append(name, String(value))
      })

      await createCategory(formData)

      window.location.reload()
    },
    async fetchCategories () {
      const { data: categoriesResponse } = await getCategories({
        paginate: 10,
        page: this.currentPage
      })

      this.categories = categoriesResponse.data
    },
    async editCategory (values) {
      const formData = new FormData()

      formData.append('id', this.categoryData?.id)
      formData.append('active', this.isActive ? 1 : 0)

      Object.entries(values).forEach(([name, value]) => {
        formData.append(name, String(value))
      })

      await updateCategory(formData)

      window.location.reload()
    },
    async deleteCategory (id) {
      if (confirm('Удалить ?')) {
        await removeCategory(id)
        await this.fetchCategories()
      }
    }
  },
  async created () {
    await this.fetchCategories()
  }
}
</script>
